<template>
	<div>
		<div id="map" v-show="!isLoadingMap"></div>
		<v-skeleton-loader
			min-height="235"
			type="image"
			loading
			v-show="isLoadingMap"
		></v-skeleton-loader>
	</div>
</template>

<script>
export default {
	name: "GMap",
	props: ["destination", "origin", "position"],
	data() {
		return {
			map: null,
			isLoadingMap: true,
		};
	},
	methods: {
		configMap() {
			// eslint-disable-next-line no-undef
			this.map = new google.maps.Map(document.getElementById("map"), {
				center: this.origin,
				zoom: 12,
				maxZoom: 20,
				minZoom: 3,
				streetViewControl: false,
				mapTypeControl: false,
				fullscreenControl: false,
				zoomControl: false,
			});

			// eslint-disable-next-line no-undef
			let dD = new google.maps.DirectionsRenderer();

			// Revisar si es un mapa complejo o simple
			if (this.position) {
				this.setMaker(this.position, "TU");
				dD.setMap(this.map);
			} else {
				// eslint-disable-next-line no-undef
				let dS = new google.maps.DirectionsService();

				let request = {
					origin: this.origin,
					destination: this.destination,
					travelMode: "DRIVING",
				};

				dD.setMap(this.map);
				dS.route(request, (result, status) => {
					if (status === "OK") {
						dD.setDirections(result);
					}
				});
			}

			// this.setMaker(this.origin, "A");
			// this.setMaker(this.destination, "B");
		},
		initMap() {
			// ->
			this.isLoadingMap = false;

			//-> Mostrar el mapa
			this.configMap();
		},
		setMaker(Points, Label) {
			// eslint-disable-next-line no-undef
			new google.maps.Marker({
				position: Points,
				map: this.map,
				label: {
					text: Label,
					color: "#FFF",
				},
			});
		},
	},
	mounted() {
		this.initMap();
	},
};
</script>

<style scoped>
#map {
	height: 235px;
}
</style>
